import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import DateRangeDisplay from 'app/components/dateRange/dateRangeDisplay/dateRangeDisplay'
import { dashboardService } from 'core/api/dashboard/dashboardService'
import theme from 'core/theme/theme'
import { formatCurrencyBR, formatDateBr, formatDateToSearch, formatDocument } from 'core/utils/globalFunctions'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import styled from 'styled-components'

const SalesByClient = () => {

    // Estado do intervalo de data
    const [initDate, setInitDate] = useState<Dayjs | null | undefined>(
        dayjs().startOf('month')
    )
    const [finalDate, setFinalDate] = useState<Dayjs | null | undefined>(
        dayjs().endOf('month')
    )

    const salesByClient = useQuery({
        queryKey: ['salesByClient', initDate, finalDate],
        staleTime: Infinity,
        queryFn: () => {
            // Adiciona 1 dia à data final
            const adjustedFinalDate = finalDate?.add(1, 'day');
            return dashboardService.getSalesByClient(
                formatDateToSearch(initDate!),
                formatDateToSearch(adjustedFinalDate!)
            );
        }
    });
    

    // Normaliza nomes
    function normalizeSellerName(name: string): string {
        return name
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }


    return (
        <TableContainer component={Paper} sx={{maxHeight: 570, overflow: 'auto', minWidth: 600}}>
            <StyledBox>
                <StyledTypography>Período</StyledTypography>
                <DateRangeDisplay
                    typeOfDatePicker={'month'}
                    initialDate={initDate}
                    endDate={finalDate}
                    setInitialDate={setInitDate}
                    setFinalDate={setFinalDate}
                />
            </StyledBox>
            <StyledTable size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Cliente</StyledTableCell>
                        <StyledTableCell>CPF</StyledTableCell>
                        <StyledTableCell>Total</StyledTableCell>
                        <StyledTableCell>Última compra</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(() => {
                        if (salesByClient.isLoading) {
                            return (
                                <StyledTableRow>
                                    <TableCell colSpan={4}>
                                        <LoadingWrapper>
                                            <CircularProgress />
                                        </LoadingWrapper>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        }
                        if (salesByClient.data) {
                            return salesByClient.data.map((row, index) => (
                                <TableRow >
                                    <StyledBodyTableCell sx={{width: '30%'}}>
                                        {normalizeSellerName(
                                            row.name ?? ""
                                        )}
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        {formatDocument(row.cpforcnpj)}
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        {formatCurrencyBR(row.total_compras)}
                                    </StyledBodyTableCell>
                                    <StyledBodyTableCell>
                                        {formatDateBr(row.ultima_compra)}
                                    </StyledBodyTableCell>
                                </TableRow>
                            ))
                        }
                        return (
                            <StyledTableRow>
                                <TableCell colSpan={3}>
                                    <NoDataMessage>
                                        Nada encontrado
                                    </NoDataMessage>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })()}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

export default SalesByClient

const StyledTable = styled(Table)`
height: 100% !important;
overflow: auto !important;
`

const StyledBox = styled(Box)`
display: flex !important;
justify-content: space-between !important;
align-items: center !important;
padding: 0 0 0 0.5rem !important;
border: solid 2px ${theme.COLORS.YELLOW2} !important;
`

const StyledTypography = styled.div`
color: ${theme.COLORS.YELLOW2} !important;
font-weight: bold !important;
font-size: 15px !important;
text-align: center !important;
`

const StyledTableCell = styled(TableCell)`
background: ${theme.COLORS.YELLOW2} !important;
color: #fff !important;
font-weight: bold !important;
font-size: 15px !important;
`

const StyledBodyTableCell = styled(TableCell)`
font-weight: bold !important;
font-size: 13px !important;
`

const CellContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
`

const StyledTableRow = styled(TableRow)`
height: 100% !important;
`

const LoadingWrapper = styled.div`
display: flex !important;
justify-content: center !important;
align-items: center !important;
height: 300px !important;
`

const NoDataMessage = styled.div`
display: flex !important;
justify-content: center !important;
align-items: center !important;
height: 300px !important;
font-size: 15px !important;
font-weight: bold !important;
color: ${theme.COLORS.YELLOW2} !important;
`
