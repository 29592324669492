import {
    TCollaboratorsByMonth,
    TMonthSalesDetails,
    TSalesByClient,
    TSalesStatus
} from 'core/models/dashboardModel'
import { axiosInstance } from '../axios/axiosInstance'
import { IResponseBody } from 'core/models/utils'
import { DASHBOARD, DASHBOARD_FIND_SALES_STATUS, DASHBOARD_SALES_BY_CLIENT, DASHBOARD_SALES_MONTH } from 'core/utils/constants'

const getCollaboratorSales = async (
    dataInicio: string,
    dataFinal: string
): Promise<TCollaboratorsByMonth[]> => {
    const response = await axiosInstance.get<
        IResponseBody<TCollaboratorsByMonth[]>
    >(DASHBOARD, {
        params: {
            dataInicio: dataInicio,
            dataFinal: dataFinal
        }
    })
    return response.data.data
}

const getMonthSales = async (
    dataInicio: string,
    dataFinal: string
): Promise<TMonthSalesDetails[]> => {
    const response = await axiosInstance.get<IResponseBody<TMonthSalesDetails[]>>(
        DASHBOARD_SALES_MONTH,
        {
            params: {
                dataInicio: dataInicio,
                dataFinal: dataFinal
            }
        }
    )
    return response.data.data
}

const getSalesStatus = async (
    dataInicio: string,
    dataFinal: string
): Promise<TSalesStatus[]> => {
    const response = await axiosInstance.get<IResponseBody<TSalesStatus[]>>(
        DASHBOARD_FIND_SALES_STATUS,
        {
            params: {
                dataInicio: dataInicio,
                dataFinal: dataFinal
            }
        }
    )
    return response.data.data
}

const getSalesByClient = async (
    dataInicio: string,
    dataFinal: string
): Promise<TSalesByClient[]> => {
    const response = await axiosInstance.get<IResponseBody<TSalesByClient[]>>(
        DASHBOARD_SALES_BY_CLIENT,
        {
            params: {
                dataInicio: dataInicio,
                dataFinal: dataFinal
            }
        }
    )
    return response.data.data
}

export const dashboardService = {
    getCollaboratorSales,
    getMonthSales,
    getSalesStatus,
    getSalesByClient
}
