import { Box, Paper, Typography } from '@mui/material';
import SellerSalesByMonth from './sellersSalesByMonth';
import ListSalesMonth from './listSalesMonth';
import SalesStatus from './salesStatus';
import SalesByClient from './salesByClient';
import styled from 'styled-components';
import theme from 'core/theme/theme';

export default function Dashboard() {
    return (
        <Box
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            }}
        >
            <Paper elevation={3} sx={{ padding: 1.3 }}>
                <ItemTitle>
                    Status de Vendas por Vendedor
                </ItemTitle>
                <SalesStatus />
            </Paper>
                <Paper elevation={3} sx={{ flex: 1, padding: 1.3 }}>
                    <ItemTitle>
                        Vendas por Forma de Pagamento
                    </ItemTitle>
                    <SellerSalesByMonth />
                </Paper>
            <Paper elevation={3} sx={{ padding: 1.3 }}>
                <ItemTitle>
                    Análise Mensal de Vendas
                </ItemTitle>
                <ListSalesMonth />
            </Paper>
        </Box>
    );
}

const ItemTitle = styled(Typography)`
    font-weight: bolder !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 22px !important;
    color: ${theme.COLORS.YELLOW2} !important;
`;
