import { ThemeProvider } from '@mui/material'
import FullScreenContainer from 'app/components/layout/layout'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PositivoTheme from 'theme'
import App from './App'
import store, { persistor } from './core/redux/store'
import './index.css'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SaleProvider } from 'core/context/SaleInfoContext'

const query = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <StrictMode>
        <ThemeProvider theme={PositivoTheme}>
            <QueryClientProvider client={query}>
                <PersistGate loading={null} persistor={persistor}>
                    <Provider store={store}>
                        <ToastContainer />
                        <BrowserRouter>
                            <SaleProvider>
                                <FullScreenContainer>
                                    <App />
                                </FullScreenContainer>
                            </SaleProvider>
                        </BrowserRouter>
                    </Provider>
                </PersistGate>
            </QueryClientProvider>
        </ThemeProvider>
    </StrictMode>
)
